import React, { useContext, useState } from "react";
import { FirebaseContext } from "../../config/firebase";
import { Formik, Form } from "formik";
import "./style.css";
import { Aanmelding, AanmeldingSchema, defaultValue } from "./Aanmelding";
import { ZodError } from "zod";
import InputField from "./InputField";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Schoolloopbaan from "./Schoolloopbaan";
import RadioButton from "./RadioButton";
import translate from "./translate";
import Leerstoornissen from "./Leerstoornissen";
import GsmSelector from "./GsmSelector";
import OpleidingenDropdown from "./OpleidingenDropdown";

type Props = {
  aanmelding: Aanmelding | undefined;
};

function replaceEmptyWithUndefined(s: string | undefined) {
  return s === "" ? undefined : s;
}

function validate(values: Aanmelding) {
  try {
    /*
      .nonstrict() negeert velden die in een vorige versie er bij stonden maar nu niet meer.
    */
    AanmeldingSchema.nonstrict().parse({
      ...values,
      emailLeerling: replaceEmptyWithUndefined(values.emailLeerling),
      emailVader: replaceEmptyWithUndefined(values.emailVader),
      emailMoeder: replaceEmptyWithUndefined(values.emailMoeder),
      emailAndere: replaceEmptyWithUndefined(values.emailAndere),
      emailContact: replaceEmptyWithUndefined(values.emailContact),
    });
  } catch (e) {
    if (e instanceof ZodError) {
      const errors = e.errors.reduce((errors, error) => {
        return {
          ...errors,
          [error.path.join(".")]: translate(error.message),
        };
      }, {});

      console.log("validation: ", values, errors);

      return errors;
    }
  }
  return {};
}

export default (props: Props) => {
  const aanmelding = props.aanmelding || defaultValue;
  const [initialSubmitDone, setInitialSubmitDone] = useState(false);
  const [saving, setSaving] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { database, auth } = useContext(FirebaseContext);
  const history = useHistory();

  const today = moment().format("YYYY-MM-DD");
  const limitDate = "2025-04-22";
  const user = auth.currentUser;

  if (today < limitDate && !user) {
    return(
    <>
    <h1>Aanmeldingsformulier Scheppersinstituut Deurne &amp; Antwerpen</h1>
    <div className="form-info">
          <div className="info">
            <p>
              Het is momenteel niet mogelijk om je digitaal aan te 
              melden voor het komende schooljaar.</p>
            <p>
              Je kan hier terecht vanaf <strong>{new Date(limitDate).toLocaleDateString("nl-BE", { weekday: "long", day: "numeric", month: "long", year: "numeric" })}</strong>.
            </p>
            <p>
              <strong>Let op:</strong> aanmeldingen voor het eerste jaar moeten
              sowieso via <a href="https://meldjeaansecundair.antwerpen.be/">meld je aan</a>.
            </p>
          </div>
          <div className="info">
            Wens je alsnog aan te melden voor het <strong>huidige schooljaar</strong>?
            <p>
            Neem dan telefonisch contact op.
            </p>
            <ul>
              <li><strong>SEA</strong> - St.-Eligius Antwerpen - Van Helmontstraat 29 - 2060 Antwerpen - 03 217 42 42</li>
              <li><strong>SMD</strong> - Sancta Maria Deurne - Pieter de Ridderstraat 5 - 2100 Deurne - 03 360 31 45</li>
              <li><strong>SED</strong> - St.-Eligius Deurne - Lakborslei 263 - 2100 Deurne - 03 326 33 59</li>
            </ul>
          </div>
    </div>
    </>
    );
  }


  async function submitForm(aanmelding: Aanmelding) {
    if (aanmelding.id === undefined) {
      try {
        setSaving(true);
        const { user } = await auth.signInAnonymously();
        let dataToWrite = {
          ...aanmelding,
          date: moment().utc().toString(),
          id: user?.uid,
        } as Aanmelding;

        // Replace all undefined values with null
        // WHY?: https://stackoverflow.com/questions/42310950/handling-undefined-values-with-firebase

        Object.keys(dataToWrite)
          .filter((key) => (dataToWrite as any)[key] === undefined)
          .forEach((key) => {
            (dataToWrite as any)[key] = null;
          });

        console.log(dataToWrite);
        await database.ref(`/aanmeldingen/${user?.uid}`).set(dataToWrite);
        setSubmitted(true);
        await auth.signOut();
      } catch (e) {
        console.log(e);
        alert(e);
      }
    } else {
      await database
        .ref(`/aanmeldingen/${aanmelding.id}`)
        .set({ ...aanmelding, date: props.aanmelding?.date });
      alert("Wijzigingen opgeslagen");
    }
  }

  if (submitted) {
    return (
      <div>Uw aanmelding is geregistreerd. U kan nu deze pagina sluiten.</div>
    );
  }

  return (
    <>
      {aanmelding.id !== undefined ? (
        <button type="button" onClick={() => history.push("/aanmeldingen")}>
          Terug naar overzicht
        </button>
      ) : null}

      <h1>Aanmeldingsformulier Scheppersinstituut Deurne &amp; Antwerpen</h1>
      <Formik<Aanmelding>
        initialValues={aanmelding}
        onSubmit={submitForm}
        validate={validate}
      >
        {(formikProps) => (
          <Form>
            <fieldset>
              <legend>Basis</legend>
              <OpleidingenDropdown />
            </fieldset>
            <fieldset>
              <legend>Verplichte velden</legend>
              <InputField name="naam" label="Familienaam kind" required />
              <InputField name="voornaam" label="Voornaam kind" required />
              <InputField
                name="geboortedatum"
                label="Geboortedatum kind"
                type="date"
                required
              />
               <InputField
                label="E-mail contact"
                name="emailContact"
                type="email"
                required
              />
              <GsmSelector name="gsm1" required />
            </fieldset>
            
            <div className="info">
                <strong>Verdere informatie</strong>
                <p> Onderstaande gegevens zijn <em> niet verplicht </em> om in te vullen bij een aanmelding.
                  <br/>Tijdens de inschrijving worden de ontbrekende gegevens aangevuld.
                </p>
              </div>


            <fieldset>
              <legend>Gegevens van de leerling</legend>

              <div className="itemMargin">
                Geslacht
                <RadioButton
                  name="geslacht"
                  options={[
                    {
                      value: "vrouw",
                      label: "Vrouw",
                    },
                    {
                      value: "man",
                      label: "Man",
                    },
                    {
                      value: "x",
                      label: "X",
                    }
                  ]}
                />
              </div>

              <InputField name="roepnaam" label="Roepnaam" />
              
              <InputField
                name="inBelgieSinds"
                label="Woont in België sinds"
                type="date"
              />
              <InputField name="nationaliteit" label="Nationaliteit" />
              <InputField name="geboorteplaats" label="Geboorteplaats" />
              <InputField name="geboorteland" label="Geboorteland" />
              <InputField
                name="rijksregisternummer"
                label="Rijksregisternummer"
              />

              <div className="info">
                <strong>Waar vind ik het rijksregisternummer?</strong>
                <ul>
                  <li>op een klevertje van het ziekenfonds</li>
                  <li>op de achterkant van de kids-ID of ID-kaart</li>
                  <li>op de voorkant van de nieuwe ID-kaarten</li>
                </ul>
              </div>

              <fieldset>
                <legend>Adres</legend>
                <InputField name="straat" label="Straat" />
                <InputField name="huisnummer" label="Huisnummer" />
                <InputField name="bus" label="Bus" />
                <InputField name="postcode" label="Postcode" />
                <InputField name="gemeente" label="Gemeente" />
              </fieldset>

              <InputField
                label="Telefoon thuis"
                name="telefoonThuis"
                type="tel"
              />
              <InputField label="Gsm-nummer leerling" name="gsmLeerling" />

              
              <GsmSelector name="gsm2" />
              <GsmSelector name="gsm3" />

              <div
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                <InputField
                  label="Ander gsm-nummer"
                  name="gsmAndere"
                  placeholder="Gsm-nummer"
                  style={{ flex: 1 }}
                />
                <InputField
                  label="Naam van persoon"
                  name="gsmAndereNaam"
                  placeholder="Naam"
                  style={{ flex: 1 }}
                />
                <InputField
                  label="Relatie met leerling"
                  name="gsmAndereRelatie"
                  placeholder="Relatie"
                  style={{ flex: 1 }}
                />
              </div>

              <InputField
                label="E-mail leerling"
                name="emailLeerling"
                type="email"
              />
              <InputField 
                label="E-mail vader" 
                name="emailVader" 
                type="email" />
              <InputField
                label="E-mail moeder"
                name="emailMoeder"
                type="email"
              />
              <InputField
                label="Andere e-mail + naam en relatie met leerling"
                name="emailAndere"
                type="text"
              />
            </fieldset>
            <fieldset>
              <legend>Informatie over het gezin</legend>
              <InputField
                label="Familienaam vader"
                name="familienaamVader"
                
              />
              <InputField
                label="Voornaam vader"
                name="voornaamVader"
              />
              <InputField
                label="Familienaam moeder"
                name="familienaamMoeder"
              />
              <InputField
                label="Voornaam moeder"
                name="voornaamMoeder"
              />              

              <InputField
                label="Aantal jongens in het gezin"
                name="aantalJongensGezin"
                type="number"
                min="0"
              />
              <InputField
                label="Aantal meisjes in het gezin"
                name="aantalMeisjesGezin"
                type="number"
                min="0"
              />
              <InputField
                label="Plaats van de leerling in het gezin (de jongste, de middelste, de oudste,...)"
                name="plaatsLeerlingGezin"
              />
              <InputField
                label="Naam broer(s) of zus(sen) die al op onze school zat(en) of nu zit(ten)"
                name="naamBroersZussenScheppers"
                component="textarea"
              />
              <InputField
                label="Leerling verblijft bij ouders - vader - moeder - pleegouders - grootouders - instelling - andere"
                name="leerlingVerblijftBij"
              />
              <InputField
                label="Met welke gebeurtenissen in het gezin moet de school rekening houden? (scheiding, co-ouderschap, overlijden)"
                name="gebeurtenissen"
                component="textarea"
              />
              <InputField
                label="Ontvangt schooltoeslag"
                name="ontvangtSchooltoeslag"
                type="checkbox"
              />
              <div className="itemMargin">
                Heeft de moeder een diploma secundair onderwijs?
                <RadioButton
                  name="moederDiplomaSecundair"
                  options={[
                    {
                      value: "ja",
                      label: "Ja, de moeder heeft een diploma secundair",
                    },
                    {
                      value: "nee",
                      label: "Nee, de moeder heeft geen diploma secundair",
                    },
                  ]}
                />
              </div>              

              <fieldset>
                <legend>Thuistaal</legend>
                <InputField label="Met vader" name="thuistaalMetVader" />
                <InputField label="Met moeder" name="thuistaalMetMoeder" />
                <InputField
                  label="Met broers/zussen"
                  name="thuistaalMetBroersZussen"
                />
              </fieldset>
            </fieldset>
            <fieldset>
              <legend>Schoolloopbaan (ook de lagere school vermelden)</legend>
              <Schoolloopbaan values={formikProps.values} />
            </fieldset>
            <fieldset>
              <legend>Aandachtspunten</legend>

              <div className="itemMargin">Zijn er aandachtspunten rond:</div>

              <fieldset>
                <legend>Inschrijvingsregels</legend>

                <div className="itemMargin">
                  Is er een gemotiveerd verslag dat aanleiding kan geven tot
                  inschrijving onder ontbindende voorwaarden?
                  <RadioButton
                    name="gemotiveerdVerslagOntbinding"
                    options={[
                      {
                        value: "ja",
                        label: "Ja",
                      },
                      {
                        value: "nee",
                        label: "Nee",
                      },
                    ]}
                  />
                </div>
                {formikProps.values.gemotiveerdVerslagOntbinding === "ja" ? (
                  <InputField
                    name="gemotiveerdVerslagOntbindingReden"
                    component="textarea"
                    label="Waarvoor is er een gemotiveerd verslag opgemaakt?"
                  />
                ) : null}
              </fieldset>
              <fieldset>
                <legend>Gezondheid</legend>

                <InputField
                  name="aandachtGezondheid"
                  component="textarea"
                  label="Zijn er aandachtspunten rond de gezondheid van uw kind?"
                />
              </fieldset>
              <fieldset>
                <legend>Leerstoornissen</legend>

                <div className="itemMargin">
                  Heeft uw kind één of meerdere leerstoornissen?
                  <RadioButton
                    name="aandachtLeerstoornissen"
                    options={[
                      {
                        value: "ja",
                        label: "Ja",
                      },
                      {
                        value: "nee",
                        label: "Nee",
                      },
                    ]}
                  />
                </div>

                {formikProps.values.aandachtLeerstoornissen === "ja" ? (
                  <Leerstoornissen values={formikProps.values} />
                ) : null}
              </fieldset>

              <div className="itemMargin">
                De leerling is ex-OKAN?
                <RadioButton
                  name="aandachtExOkan"
                  options={[
                    {
                      value: "ja",
                      label: "Ja",
                    },
                    {
                      value: "nee",
                      label: "Nee",
                    },
                  ]}
                />
              </div>

              <InputField
                name="aandachtAndere"
                component="textarea"
                label="Zijn er nog andere aandachtspunten?"
              />
            </fieldset>
            {aanmelding.id !== undefined ? (
              <fieldset>
                <legend>Opmerkingen van de leco bij deze inschrijving</legend>
                <InputField name="lecoOpmerkingen" component="textarea" />
              </fieldset>
            ) : null}
            <fieldset>
              <legend>Toestemmingen</legend>

              <div className="itemMargin">
                Als het nodig is, vragen wij soms hulp aan het CLB dat verbonden
                is aan onze school. Dat kan te maken hebben met gezondheid, een
                leerstoornis, ... Geeft u daarvoor toestemming?/ Gaat u daarmee
                akkoord?
                <RadioButton
                  name="toelatingCLBDelen"
                  options={[
                    {
                      value: "ja",
                      label: "Ja",
                    },
                    {
                      value: "nee",
                      label: "Nee",
                    },
                  ]}
                />
              </div>
            </fieldset>
            <fieldset>
              <legend>Varia</legend>

              <div className="itemMargin">
                Hoe hebben de ouders de school leren kennen?
                <RadioButton
                  name="oudersSchoolLerenKennen"
                  options={[
                    {
                      value: "familieOfVrienden",
                      label: "Door familie of vrienden aanbevolen",
                    },
                    {
                      value: "basisschool",
                      label: "Door de basisschool aanbevolen",
                    },
                    {
                      value: "andereSchool",
                      label: "Door een andere school aanbevolen",
                    },
                    {
                      value: "bezoekAanSchool",
                      label:
                        "Door een bezoek aan de school (op afspraak, met 6de leerjaar, ...)",
                    },
                    {
                      value: "andereActiviteitVanSchool",
                      label:
                        "Op een andere activiteit van de school (workshop, show CM, ...)",
                    },
                    {
                      value: "advertentiePers",
                      label: "Door een advertentie in de media",
                    },
                    {
                      value: "brochureOfFolder",
                      label: "Door een brochure of folder",
                    },
                    {
                      value: "scholenbeurs",
                      label: "Op een scholenbeurs",
                    },
                    {
                      value: "schoolwebsite",
                      label: "Via de schoolwebsite",
                    },
                    {
                      value: "facebook",
                      label: "Via de facebookpagina",
                    },
                    {
                      value: "onderwijskiezer",
                      label: `Via de site "onderwijskiezer"`,
                    },
                    {
                      value: "andere",
                      label: "Andere",
                    },
                  ]}
                />
                {formikProps.values.oudersSchoolLerenKennen === "andere" ? (
                  <InputField
                    name="oudersSchoolLerenKennenAndere"
                    label="Welke andere manier?"
                  />
                ) : null}
              </div>
            </fieldset>

            <fieldset>
              <legend>Ingeschreven</legend>
              <InputField
                label="Is leerling officieel ingeschreven?"
                name="ingeschreven"
                type="checkbox"
                disabled={!auth.currentUser || auth.currentUser.isAnonymous}
              />
            </fieldset>

            {!formikProps.isValid && initialSubmitDone ? (
              <>
                <div className="error">Er zijn nog fouten in het formulier</div>
              </>
            ) : null}

            <button
              type="submit"
              onClick={() => setInitialSubmitDone(true)}
              disabled={saving}
            >
              {aanmelding.id === undefined
                ? "Verzenden"
                : "Wijzigingen opslaan"}
            </button>
            {/*
              Half-ingevuld formulier niet meer in localstorage opslaan
              {aanmelding.id === undefined ? <Persist name="aanmelding" /> : null}
            */}
          </Form>
        )}
      </Formik>
    </>
  );
};
