import React, { useContext, useState } from "react";
import AanmeldingsFormulier from "./components/AanmeldingsFormulier";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminDashboard from "./components/AdminDashboard";
import EditAanmelding from "./components/AdminDashboard/EditAanmelding";
import Opleidingen from "./components/AdminDashboard/Opleidingen";
import Login from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import UserContext from "./components/Auth/UserContext";
import { FirebaseContext } from "./config/firebase";


function isAdminUser(user: firebase.User | null) {
  return user?.email?.endsWith("@scheppersinstituut.be") || false;
  //return true;
}

function App() {
  const { auth } = useContext(FirebaseContext);
  const [user, setUser] = useState<firebase.User | null>(auth.currentUser);

  auth.onAuthStateChanged((newUser) => {
    setUser(newUser);

    if (newUser && !isAdminUser(newUser)) {
      auth.signOut();
    }
  });

  return (
    <main>
      <UserContext.Provider
        value={{
          user,
          adminUser: isAdminUser(user),
        }}
      >
        <Router>
          <Switch>
            <Route path="/" component={AanmeldingsFormulier} exact />
            <Route path="/login" component={Login} exact />

            <ProtectedRoute
              path="/aanmeldingen"
              component={AdminDashboard}
              exact
            />
            <ProtectedRoute
              path="/aanmeldingen/:id"
              component={EditAanmelding}
              exact
            />

            <ProtectedRoute
              path="/opleidingen"
              component={Opleidingen}
              exact
            />
          
          </Switch>
        </Router>
      </UserContext.Provider>
    </main>
  );
}

export default App;
