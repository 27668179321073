import React, { useState } from "react";
import { Opleiding } from "./Opleiding";

type Props = {
    opleiding: Opleiding;
    deleteOpleiding: (opleidingId: string) => void;
    saveOpleiding: (opleidingId: string, updatedOpleiding: Opleiding) => void;
};

export default ({ opleiding, deleteOpleiding, saveOpleiding }: Props) => {
    const [editedOpleiding, setEditedOpleiding] = useState<Opleiding>({ ...opleiding });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target as HTMLInputElement | HTMLSelectElement;
        setEditedOpleiding(prev => ({
            ...prev,
            [name]: name === "jaar" ? Number(value) : value,  // Ensure `jaar` is a number
        }));
    };

    return (
        <tr key={opleiding.id} className="opleidingrij">
            {/* Jaar Dropdown */}
            <td>
                <select name="jaar" value={editedOpleiding.jaar} onChange={handleChange}>
                    {[1, 2, 3, 4, 5, 6].map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </td>

            {/* Richting Input Field */}
            <td>
                <input
                    type="text"
                    name="richting"
                    value={editedOpleiding.richting}
                    onChange={handleChange}
                />
            </td>

            {/* Campus Dropdown */}
            <td>
                <select name="campus" value={editedOpleiding.campus.toUpperCase()} onChange={handleChange}>
                    {["SEA", "SED", "SMD"].map((campus) => (
                        <option key={campus} value={campus}>
                            {campus}
                        </option>
                    ))}
                </select>
            </td>

            {/* Save Button */}
            <td>
                <button
                    onClick={() => saveOpleiding(opleiding.id!, editedOpleiding)}
                    className="opslaan"    
                >
                    Opslaan
                </button>
            </td>


            {/* Delete Button */}
            <td>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        deleteOpleiding(opleiding.id!);
                    }}
                    className="verwijderen"
                >
                    Verwijderen
                </button>
            </td>
        </tr>
    );
};
