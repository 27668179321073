import React, { useContext, useState } from "react";
import { Field, FieldProps, useFormikContext } from "formik";
import { FirebaseContext } from "../../config/firebase";
import { useListVals } from "react-firebase-hooks/database";
import JaarSelector from "./JaarSelector";
import RichtingSelector from "./RichtingSelector";
import CampusSelector from "./CampusSelector";
import Loader from "../Loader";

interface Opleiding {
  jaar: number;
  campus: string;
  richting: string;
}

const OpleidingenDropdown: React.FC = () => {
  const { database, auth } = useContext(FirebaseContext);
  const { values, setFieldValue } = useFormikContext<{ jaar: number | ""; richting: string; campus: string }>();

  const [opleidingen, loading, error] = useListVals<Opleiding>(database.ref("/opleidingen"));

  if (loading) return <Loader />;
  if (error) return <p>Fout bij het laden: {error.message}</p>;

  const opleidingenArray = opleidingen || [];

  const jaren = Array.from(new Set(opleidingenArray.map((o) => o.jaar))).sort((a, b) => a - b);

  const richtingen = values.jaar
    ? Array.from(new Set(opleidingenArray.filter((o) => o.jaar === values.jaar).map((o) => o.richting))).sort()
    : [];

  const campussen = values.richting && values.richting !== "Selecteer een richting"
    ? Array.from(new Set(opleidingenArray.filter((o) => o.jaar === values.jaar && o.richting === values.richting).map((o) => o.campus))).sort()
    : [];

  return (
    <div className="form-container">
      {/* Jaar Field */}
      <div className="form-row">
        <div className="form-field">
          <Field name="jaar">
            {({ field }: FieldProps) => (
              <JaarSelector
                jaren={jaren}
                selectedJaar={field.value}
                setSelectedJaar={(value) => {
                  setFieldValue("jaar", value);
                  setFieldValue("richting", "Selecteer een richting"); // Explicitly reset
                  setFieldValue("campus", "Selecteer een campus"); // Explicitly reset
                }}
              />
            )}
          </Field>
        </div>
        <div className="form-info">
          <div className="info small">
            <strong>1e jaren</strong>
            <p>
              Leerlingen van het 1e jaar A-stroom en B-stroom
              kunnen niet aanmelden via deze weg.<br/>  
              Voor hen moet je via <strong><a href="https://meldjeaansecundair.antwerpen.be/">meld je aan</a></strong> van de Stad Antwerpen.
            </p>
            </div>
          </div>
      </div>

      {/* Richting Field */}
      <div className="form-row">
        <div className="form-field">
          <Field name="richting">
            {({ field }: FieldProps) => (
              <RichtingSelector
                richtingen={richtingen}
                selectedRichting={field.value}
                setSelectedRichting={(value) => {
                  setFieldValue("richting", value);
                  setFieldValue("campus", "Selecteer een campus"); // Explicitly reset
                }}
                disabled={!values.jaar}
              />
            )}
          </Field>
        </div>
        <div className="form-info">
          <div className="info small">
            <strong>De finaliteiten</strong>
            <ul>
              <li>(D) Doorstroomgerichte finaliteit</li>
              <li>(D/A) Dubbele finaliteit</li>
              <li>(A) Arbeidsmarktgerichte finaliteit</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Campus Field */}
      <div className="form-row">
        <div className="form-field">
          <Field name="campus">
            {({ field }: FieldProps) => (
              <CampusSelector
                campussen={campussen}
                selectedCampus={field.value}
                setSelectedCampus={(value) => setFieldValue("campus", value)}
                disabled={!values.richting || values.richting === "Selecteer een richting"}
              />
            )}
          </Field>
        </div>
        <div className="form-info">
          <div className="info small">
            <strong>Waar bevindt zich welke campus?</strong>
            <ul>
              <li><strong>SEA</strong> - St.-Eligius Antwerpen - Van Helmontstraat 29 - 2060 Antwerpen - voor hulp bij invullen 03 217 42 42</li>
              <li><strong>SMD</strong> - Sancta Maria Deurne - Pieter de Ridderstraat 5 - 2100 Deurne - voor hulp bij invullen 03 360 31 45</li>
              <li><strong>SED</strong> - St.-Eligius Deurne - Lakborslei 263 - 2100 Deurne - voor hulp bij invullen 03 326 33 59</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};


export default OpleidingenDropdown;
