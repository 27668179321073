import * as z from "zod";
import moment from "moment";

const checkbox = z.boolean();
const jaNee = z.union([z.literal("ja"), z.literal("nee")]);

const SchoolloopbaanJaarSchema = z.object({
  schooljaar: z.string(),
  gevolgdJaar: z.string(),
  studierichting: z.string(),
  school: z.string(),
  attest: z.string(),
});

const gsmType = z.union([
  z.literal("moeder"),
  z.literal("vader"),
  z.literal("voogd"),
  z.literal("grootouders"),
  z.literal("pleegouders"),
  z.literal("andere"),
]);

export const AanmeldingSchema = z.object({
  id: z.string().optional(),

  campus: z.string(),
  jaar: z.number(),
  richting: z.string().nonempty(),

  date: z.string(),
  voornaam: z.string().nonempty(),
  naam: z.string().nonempty(),

  geslacht: z.union([z.literal("man"), z.literal("vrouw"), z.literal("x"),z.literal("")]),
  roepnaam: z.string().optional(),
  geboortedatum: z.string().nonempty(),
  inBelgieSinds: z.string().optional(),
  nationaliteit: z.string().optional(),
  geboorteplaats: z.string().optional(),
  geboorteland: z.string().optional(),
  rijksregisternummer: z.string().optional(),

  straat: z.string(),
  huisnummer: z.string(),
  bus: z.string().optional(),
  postcode: z.string(),
  gemeente: z.string(),

  telefoonThuis: z.string().optional(),
  gsmLeerling: z.string().optional(),

  gsm1Type: gsmType,
  gsm1: z.string().nonempty(),
  gsm2Type: gsmType.optional(),
  gsm2: z.string().optional(),
  gsm3Type: gsmType.optional(),
  gsm3: z.string().optional(),
  gsmAndere: z.string().optional(),
  gsmAndereNaam: z.string().optional(),
  gsmAndereRelatie: z.string().optional(),

  emailContact: z.string().email().nonempty(),
  emailLeerling: z.string().email().optional(),
  emailVader: z.string().email().optional(),
  emailMoeder: z.string().email().optional(),
  emailAndere: z.string().optional(),

  ontvangtSchooltoeslag: checkbox,
  moederDiplomaSecundair: jaNee,

  familienaamVader: z.string().optional(),
  voornaamVader: z.string().optional(),
  familienaamMoeder: z.string().optional(),
  voornaamMoeder: z.string().optional(),

  aantalJongensGezin: z.number(),
  aantalMeisjesGezin: z.number(),
  plaatsLeerlingGezin: z.string().optional(),
  naamBroersZussenScheppers: z.string(),

  leerlingVerblijftBij: z.string(),
  gebeurtenissen: z.string(),
  thuistaalMetVader: z.string(),
  thuistaalMetMoeder: z.string(),
  thuistaalMetBroersZussen: z.string(),

  schoolloopbaan: z.array(SchoolloopbaanJaarSchema),

  gemotiveerdVerslagOntbinding: jaNee,
  gemotiveerdVerslagOntbindingReden: z.string().optional(),

  aandachtGezondheid: z.string(),
  aandachtLeerstoornissen: jaNee,
  leerstoornissen: z
    .array(
      z.union([
        z.literal("dyslexie"),
        z.literal("dyscalculie"),
        z.literal("dysorthografie"),
        z.literal("ass"),
        z.literal("dyspraxie"),
      ])
    )
    .optional(),
  leerstoornissenGeattesteerd: jaNee,
  aandachtGOKContact: jaNee,
  aandachtGOKContactWie: z.string().optional(),
  aandachtExOkan: jaNee,
  aandachtAndere: z.string(),

  lecoOpmerkingen: z.string(),
  toelatingCLBDelen: jaNee,

  oudersSchoolLerenKennen: z.string().optional(),
  oudersSchoolLerenKennenAndere: z.string().optional(),
  ingeschreven: checkbox,
});

export type Aanmelding = z.infer<typeof AanmeldingSchema>;
export type SchoolloopbaanJaar = z.infer<typeof SchoolloopbaanJaarSchema>;

const year = moment().year();
const years = new Array(7)
  .fill(null)
  .map((_, i) => [year - i - 1, year - i].join("-"));
const defaultSchoolloopbaan: SchoolloopbaanJaar[] = years.map((schooljaar) => ({
  schooljaar,
  gevolgdJaar: "",
  studierichting: "",
  school: "",
  attest: "",
}));

export const defaultValue: Aanmelding = {
  id: undefined,

  campus: "",
  jaar: 0,
  richting: "",

  date: "",
  voornaam: "",
  naam: "",

  geslacht: "",
  roepnaam: "",
  geboortedatum: "",
  inBelgieSinds: "",
  nationaliteit: "",
  geboorteplaats: "",
  geboorteland: "",
  rijksregisternummer: "",

  straat: "",
  huisnummer: "",
  bus: "",
  postcode: "",
  gemeente: "",

  telefoonThuis: "",
  gsmLeerling: "",
  gsm1Type: "moeder",
  gsm1: "",
  gsm2Type: "vader",
  gsm2: "",
  gsm3Type: "grootouders",
  gsm3: "",
  emailContact: "",
  emailLeerling: undefined,
  emailVader: undefined,
  emailMoeder: undefined,
  emailAndere: undefined,

  ontvangtSchooltoeslag: false,
  moederDiplomaSecundair: "ja",

  familienaamVader: "",
  voornaamVader: "",
  familienaamMoeder: "",
  voornaamMoeder: "",
  aantalJongensGezin: 0,
  aantalMeisjesGezin: 0,
  plaatsLeerlingGezin: "",
  naamBroersZussenScheppers: "",

  leerlingVerblijftBij: "",
  gebeurtenissen: "",
  thuistaalMetVader: "",
  thuistaalMetMoeder: "",
  thuistaalMetBroersZussen: "",

  schoolloopbaan: defaultSchoolloopbaan,

  gemotiveerdVerslagOntbinding: "nee",
  gemotiveerdVerslagOntbindingReden: "",
  aandachtGezondheid: "",
  aandachtLeerstoornissen: "nee",
  leerstoornissen: [],
  leerstoornissenGeattesteerd: "nee",
  aandachtGOKContact: "nee",
  aandachtGOKContactWie: undefined,
  aandachtExOkan: "nee",
  aandachtAndere: "",

  lecoOpmerkingen: "",
  toelatingCLBDelen: "ja",

  oudersSchoolLerenKennen: "",
  oudersSchoolLerenKennenAndere: "",
  
  ingeschreven: false,
};
