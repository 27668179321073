import React, {useContext} from "react";
import { FirebaseContext } from "../../config/firebase";

type JaarSelectorProps = {
  jaren: number[];
  selectedJaar: number | "";
  setSelectedJaar: (jaar: number | "") => void;
}

const JaarSelector: React.FC<JaarSelectorProps> = ({ jaren, selectedJaar, setSelectedJaar }) => {
  const { auth } = useContext(FirebaseContext);
  const isAuthenticated = auth.currentUser && !auth.currentUser.isAnonymous;
  const filteredJaren = isAuthenticated ? jaren : jaren.filter((jaar) => jaar !== 1);

  return (
    <div className="opleiding">
      <select value={selectedJaar} onChange={(e) => setSelectedJaar(Number(e.target.value) || "")}>
        <option value="">Selecteer een jaar</option>
        {filteredJaren.map((jaar) => (
          <option key={jaar} value={jaar}>{jaar}</option>
        ))}
      </select>
    </div>
  );
};

export default JaarSelector;