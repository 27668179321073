import { sortBy } from "lodash";
import React from "react";
import { Opleiding } from "../Opleiding";
import OpleidingRow from "../OpleidingRow";

type Props = {
  jaar: number;
  opleidingen: Opleiding[];
  deleteOpleiding: (opleidingId: string) => void;
  saveOpleiding: (opleidingId: string, updatedOpleiding: Opleiding) => void;
};

export default ({jaar, opleidingen, deleteOpleiding, saveOpleiding}:Props) => {
    const sortedOpleidingen = sortBy(opleidingen, x => x.richting);

    return(
        <>
            <h2>{jaar}e jaar ({sortedOpleidingen.length} opleidingen)</h2>
            <table>
                <thead>
                    <tr>
                        <th>Jaar</th>
                        <th>richting</th>
                        <th>Campus</th>
                        <th>Bewerken</th>
                        <th>Verwijderen</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedOpleidingen.map((opleiding) => (
                        <OpleidingRow
                            opleiding={opleiding}
                            deleteOpleiding={deleteOpleiding}
                            saveOpleiding={saveOpleiding}
                        />
                    ))}
                </tbody>
            </table>
            
        </>
    );
};