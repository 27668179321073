import React, { useContext, useState } from "react";
import { FirebaseContext } from "../../config/firebase";
import { useListVals } from "react-firebase-hooks/database";
import Loader from "../Loader";
import { Aanmelding } from "../AanmeldingsFormulier/Aanmelding";
import "./style.css";
import { useHistory } from "react-router-dom";
import UserContext from "../Auth/UserContext";
import groupBy from "lodash.groupby";
import AanmeldingGroup from "./AanmeldingGroup";

export default () => {
  const { database, auth } = useContext(FirebaseContext);
  const { user } = useContext(UserContext);
  const [aanmeldingen, loading, error] = useListVals<Aanmelding>(
    database.ref("/aanmeldingen"),
    { keyField: "id" }
  );
  const [filter, setFilter] = useState("");
  const history = useHistory();

  async function deleteAanmelding(aanmeldingId: string) {
    if (
      window.confirm("Ben je zeker dat je deze aanmelding wilt verwijderen?")
    ) {
      await database.ref(`/aanmeldingen/${aanmeldingId}`).remove();
    }
  }

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <>Er is een fout opgetreden: {JSON.stringify(error)}</>;
  }

  if (!aanmeldingen) {
    return <div>Geen aanmeldingen in het systeem</div>;
  }

  const filteredAanmeldingen = aanmeldingen.filter((x) => {
    const voornaam = (x.voornaam || "").toLowerCase();
    const naam = (x.naam || "").toLowerCase();
    const jaarEnRichting = `${x.jaar}e jaar ${x.richting}`.toLowerCase();
    const campus = (x.campus || "").toLowerCase();
    const sanitizedFilter = filter.toLowerCase().trim();

    return (
      voornaam.includes(sanitizedFilter) ||
      naam.includes(sanitizedFilter) ||
      campus.includes(sanitizedFilter) ||
      jaarEnRichting.includes(sanitizedFilter) ||
      `${voornaam} ${naam}`.includes(sanitizedFilter)
    );
  });
  const aanmeldingenByYear = groupBy(filteredAanmeldingen, (x) => x.jaar);
  const jaren = Object.keys(aanmeldingenByYear).map(x => parseInt(x));

  return (
    <>
      <div className="siteheader">
            <div className="navigatie">
              <a href="/opleidingen">Beheer van opleidingen</a>
            </div>
            <div className="afmelden">
            Aangemeld als: {user?.displayName}
            &nbsp;
            <button
              onClick={() =>
                auth.signOut().then(() =>
                  setTimeout(() => {
                  history.push("/login");
                  }, 100)
                )
              }
            >
              Afmelden
            </button>
            </div>
        </div>


      <input
        placeholder="Zoeken"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        className="search"
      />

      {jaren.map(jaar => 
        <AanmeldingGroup
          jaar={jaar}
          aanmeldingen={aanmeldingenByYear[jaar]}
          deleteAanmelding={deleteAanmelding}
        />
      )}
    </>
  );
};
