import React from "react";

interface CampusSelectorProps {
  campussen: string[];
  selectedCampus: string;
  setSelectedCampus: (campus: string) => void;
  disabled: boolean; 
}

const CampusSelector: React.FC<CampusSelectorProps> = ({ campussen, selectedCampus, setSelectedCampus, disabled }) => {
  return (
    <div className="opleiding">
      <select value={selectedCampus} onChange={(e) => setSelectedCampus(e.target.value)} disabled={disabled}>
        <option value="">Selecteer een campus</option>
        {campussen.map((campus) => (
          <option key={campus} value={campus}>{campus.toUpperCase()}</option>
        ))}
      </select>
    </div>
  );
};

export default CampusSelector;