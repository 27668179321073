import React from "react";

interface RichtingSelectorProps {
  richtingen: string[];
  selectedRichting: string;
  setSelectedRichting: (richting: string) => void;
  disabled: boolean;
}

const RichtingSelector: React.FC<RichtingSelectorProps> = ({ richtingen, selectedRichting, setSelectedRichting, disabled }) => {
  return (
    <div className="opleiding">
      <select name="richting" value={selectedRichting} onChange={(e) => setSelectedRichting(e.target.value)} disabled={disabled}>
        <option value="">Selecteer een richting</option>
        {richtingen.map((richting) => (
          <option key={richting} value={richting}>{richting}</option>
        ))}
      </select>
    </div>
  );
};

export default RichtingSelector;