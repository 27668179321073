import React, { useContext, useState } from "react";
import { FirebaseContext } from "../../config/firebase";
import { useListVals } from "react-firebase-hooks/database";
import Loader from "../Loader";
import { Opleiding } from "./Opleiding";
import "./style.css";
import { useHistory } from "react-router-dom";
import UserContext from "../Auth/UserContext";
import groupBy from "lodash.groupby";
import OpleidingGroup from "./OpleidingGroup";
import OpleidingRow from "./OpleidingRow";

export default() => {
    const { database, auth } = useContext(FirebaseContext);
    const { user } = useContext(UserContext);
    const [opleidingen, loading, error] = useListVals<Opleiding>(
        database.ref("/opleidingen"),
        {keyField: "id"}
    );
  
    // Default empty opleiding for new entries
    const emptyOpleiding: Opleiding = { id: "", jaar: 1, campus: "SEA", richting: "" };

    const history = useHistory();

    async function deleteOpleiding(opleidingId:string) {
        if (
            window.confirm("Ben je zeker dat je deze opleiding wilt verwijderen?")
          ) {
            await database.ref(`/opleidingen/${opleidingId}`).remove();
          }
    }

    async function saveOpleiding(opleidingId: string, updatedOpleiding: Opleiding) {
      try {
        if (!opleidingId) {
          // Create a new entry if no ID is provided
          const newRef = database.ref("/opleidingen").push();
          opleidingId = newRef.key!; // Get the generated ID
          updatedOpleiding.id = opleidingId; // Store the ID in the object
        }
        
        // Save the opleiding (new or existing)
        await database.ref(`/opleidingen/${opleidingId}`).set(updatedOpleiding);
        alert("Gewijzigde/Nieuwe opleiding opgeslagen!");
      } catch (error) {
        alert("Fout bij opslaan");
      }
    }

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <>Er is een fout opgetreden: {JSON.stringify(error)}</>;
    }

    if (!opleidingen) {
        return <div>Geen opleidingen in het systeem</div>;
    }
    
    const opleidingenByYear = groupBy(opleidingen, (x) => x.jaar);
    const jaren = Object.keys(opleidingenByYear).map(x => parseInt(x));

    return(
        <>
        <div className="siteheader">
            <div className="navigatie">
              <a href="/aanmeldingen">Beheer van aanmeldingen</a>
            </div>
            <div className="afmelden">
            Aangemeld als: {user?.displayName}
            &nbsp;
            <button
              onClick={() =>
                auth.signOut().then(() =>
                  setTimeout(() => {
                  history.push("/login");
                  }, 100)
                )
              }
            >
              Afmelden
            </button>
            </div>
        </div>

      <h2>Nieuwe richting toevoegen</h2>
      <table>
            <thead>
              <th>Jaar</th>
              <th>Richting</th>
              <th>Campus</th>
              <th>Opslaan</th>
            </thead>
            <tbody className="nieuwerichting">
                <OpleidingRow 
                    opleiding={emptyOpleiding} 
                    deleteOpleiding={() => {}} // No delete needed for new items
                    saveOpleiding={saveOpleiding}
                />
            </tbody>
        </table>

      {jaren.map(jaar => 
        <OpleidingGroup
            jaar={jaar}
            opleidingen={opleidingenByYear[jaar]}
            deleteOpleiding={deleteOpleiding}
            saveOpleiding={saveOpleiding}
        />
      )}
    </>
    );
};