const translations: { [key: string]: string } = {
  "Expected number, received string": "Dit moet een getal zijn",
  "Should be at least 1 characters": "Je moet dit vak verplicht invullen.",
  "Value should be greater than or equal to 1":
    "Je moet een studiejaar selecteren",
  "Invalid email": "Dit is geen geldig e-mail adres",
  Required: "Je moet dit vak verplicht invullen.",
  "Should be at least 11 characters": "Dit moet minstens 11 cijfers bevatten.",
  "Invalid input": "Ongeldige selectie",
};

export default function translate(text: string) {
  const result = translations[text];
  return result || text;
}
